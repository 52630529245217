<script>
export default {
  props: ["clr"],
};
</script>
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4673 6.68604L12.0002 14.1261L8.96191 11.0879"
      stroke="#3AAB0D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.2877 10.1165C21.1957 14.1092 19.1293 18.182 15.3711 19.8072C11.6129 21.4324 7.23004 20.1485 4.94277 16.7523C2.6555 13.3561 3.11334 8.81214 6.03215 5.9405C8.95095 3.06886 13.5018 2.6851 16.8603 5.02739"
      stroke="#3AAB0D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
