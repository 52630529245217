import dialogWarning from "@/assets/AppIcons/dialogWarning";
import expiredTimeOrange from "@/assets/AppIcons/expiredTimeOrange";
import expiredTimeRed from "@/assets/AppIcons/expiredTimeRed";
import successfulPayment from "@/assets/AppIcons/successfulPayment";
import warningPayment from "@/assets/AppIcons/warningPayment";
import close from "@/assets/AppIcons/closeIcon";
import copyToClipboard from "@/assets/AppIcons/copyToClipboard";
import successIcon from "@/assets/AppIcons/successIcon";
import errorIcon from "@/assets/AppIcons/errorIcon";
import warning from "@/assets/AppIcons/warningIcon";

const CustomIcons = {
  warningPayment: {
    component: warningPayment,
  },
  warning: {
    component: warning,
  },
  successIcon: {
    component: successIcon,
  },
  copyToClipboard: {
    component: copyToClipboard,
  },
  close: {
    component: close,
  },
  dialogWarning: {
    component: dialogWarning,
  },
  expiredTimeOrange: {
    component: expiredTimeOrange,
  },
  expiredTimeRed: {
    component: expiredTimeRed,
  },
  successfulPayment: {
    component: successfulPayment,
  },
  errorIcon: {
    component: errorIcon,
  },
};

export default CustomIcons;
