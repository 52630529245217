<template>
  <v-app id="app">
    <transition-page>
      <router-view />
    </transition-page>
  </v-app>
</template>
<script>
import TransitionPage from "@/components/Layout/TransitionPage";
export default {
  components: { TransitionPage },
};
</script>
