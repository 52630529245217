import API_GATEWAY from "@/services/config/main-axios";

const mainService = {
  getInfo(trackingCode) {
    return API_GATEWAY.get(`order/deposit/getInfo/${trackingCode}`);
  },

  showCurrency(trackingCode) {
    return API_GATEWAY.get(`order/deposit/showCurrency/${trackingCode}`);
  },

  chooseCurrency(currencyInfo) {
    return API_GATEWAY.post(`order/deposit/chooseCurrency`, currencyInfo);
  },

  savePurchaseData(emailInfo) {
    return API_GATEWAY.post(`order/deposit/savePurchaseData`, emailInfo);
  },

  getChosenCurrency(trackingCode) {
    return API_GATEWAY.get(`order/deposit/getCurrencyChoose/${trackingCode}`);
  },

  underpayData(trackingCode) {
    return API_GATEWAY.get(`order/deposit/receipt/${trackingCode}`);
  },

  goToSite(trackingCode) {
    return API_GATEWAY.get(`order/deposit/redirectMerchantUrl/${trackingCode}`);
  },
};

export default mainService;
