<script>
export default {
  props: ["clr"],
};
</script>
<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 29.52V21.105"
      stroke="#E25254"
      stroke-width="3.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.9978 36.2812C26.6873 36.2812 26.4353 36.5333 26.4375 36.8438C26.4375 37.1542 26.6895 37.4062 27 37.4062C27.3105 37.4062 27.5625 37.1542 27.5625 36.8438C27.5625 36.5333 27.3105 36.2812 26.9978 36.2812"
      stroke="#E25254"
      stroke-width="3.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.5657 9.31268L48.7984 39.4717C50.8009 42.9772 48.2697 47.3399 44.2332 47.3399H9.76769C5.72894 47.3399 3.19769 42.9772 5.20244 39.4717L22.4352 9.31268C24.4534 5.77793 29.5474 5.77793 31.5657 9.31268Z"
      stroke="#E25254"
      stroke-width="3.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
