import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// import successIcon from "@/assets/AppIcons/successIcon";

const options = {
  position: "bottom-center",
  closeButton: false,
  hideProgressBar: true,
  timeout: 1500,
  closeOnClick: false,
  toastClassName: "custom-toast",
  bodyClassName: "custom-toast-body",
  // icon: successIcon,
};

Vue.use(Toast, options);
