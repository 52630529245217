<script>
export default {
  props: ["clr"],
};
</script>
<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.99805"
      y="7.49805"
      width="14.0058"
      height="14.0058"
      rx="2"
      stroke="#8A6DDE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.99825 17.5024H4.99742C3.89239 17.5024 2.99658 16.6066 2.99658 15.5016V5.49742C2.99658 4.39239 3.89239 3.49658 4.99742 3.49658H15.0016C16.1066 3.49658 17.0024 4.39239 17.0024 5.49742V7.49825"
      stroke="#8A6DDE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
