<script>
export default {
  props: ["clr"],
};
</script>
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.3335 5.3335L10.6668 10.6668"
      stroke="#21242B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6668 5.3335L5.3335 10.6668"
      stroke="#21242B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
