import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

let status = "",
  ttl = "";

const routes = [
  {
    path: "/",
    redirect: "/payment",
  },
  {
    path: "/payment/:trackingCode",
    name: "root",
    redirect: { name: "contactInformation" },
    meta: { transitionName: "slide" },
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/views/Layout"),
    children: [
      {
        path: "contact-info",
        name: "contactInformation",
        meta: { transitionName: "slide" },
        component: () =>
          import(
            /* webpackChunkName: "contact-information" */ "@/components/Payment/ContactInformation"
          ),
      },
      {
        path: "select-currency",
        name: "currencySelection",
        meta: { transitionName: "slide" },
        component: () =>
          import(
            /* webpackChunkName: "currency-selection" */ "@/components/Payment/CurrencySelection"
          ),
      },
      {
        path: "pay",
        name: "payment",
        meta: { transitionName: "slide" },
        component: () =>
          import(
            /* webpackChunkName: "payment-component" */ "@/components/Payment/Payment"
          ),
        beforeEnter: (to, from, next) => {
          if (status === "UNDERPAID" && ttl <= 0) {
            next({
              name: "underPayment",
              params: { trackingCode: to.params.trackingCode },
              replace: true,
            });
          } else next();
        },
      },
      {
        path: "expired",
        name: "expiredPayment",
        meta: { transitionName: "slide", noCounter: true },
        component: () =>
          import(
            /* webpackChunkName: "expired-payment-component" */ "@/components/Payment/Message/ExpiredPayment"
          ),
      },
      {
        path: "failed",
        name: "failed",
        meta: { transitionName: "slide", noCounter: true },
        component: () =>
          import(
            /* webpackChunkName: "expired-component" */ "@/components/Payment/Message/Expired"
          ),
      },
      {
        path: "complete",
        name: "paymentComplete",
        meta: { transitionName: "slide", noCounter: true },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "payment-complete" */ "@/components/Payment/Message/PaymentComplete"
          ),
      },
      {
        path: "under-payment",
        name: "underPayment",
        meta: { transitionName: "slide", noCounter: true },
        component: () =>
          import(
            /* webpackChunkName: "underPayment-component" */ "@/components/Payment/Message/UnderPayment"
          ),
      },
      {
        path: "*",
        name: "404",
        meta: { transitionName: "slide" },
        redirect: { name: "contactInformation" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const mode = {
  PENDING: "payment",
  UNDERPAID: "payment",
  PROCESSING: "expiredPayment",
  FAILED: "failed",
  SUCCESS: "paymentComplete",
  OVER_PAID: "paymentComplete",
};

router.beforeEach((to, from, next) => {
  const order = store.dispatch("orderInfo/ACTION_ORDER_INFO", to.params);
  order
    .then((res) => {
      status = res.data.data.status;
      ttl = res.data.data.ttl;
      // eslint-disable-next-line no-prototype-builtins
      if (mode.hasOwnProperty(status)) {
        if (
          to.name === mode[status] ||
          (to.name === "underPayment" && ttl <= 0)
        ) {
          next({
            params: { trackingCode: to.params.trackingCode },
            replace: true,
          });
        } else {
          next({
            name: mode[status],
            params: { trackingCode: to.params.trackingCode },
            replace: true,
          });
        }
      } else {
        const checkDestinationPath =
          Object.values(mode).find((val) => to.name === val) || false;
        if (!checkDestinationPath) {
          next({
            params: { trackingCode: to.params.trackingCode },
            replace: true,
          });
        } else {
          next({
            name: "contactInformation",
            params: { trackingCode: to.params.trackingCode },
            replace: true,
          });
        }
      }
    })
    .catch(() => {
      next();
    });
});

export default router;
