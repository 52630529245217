import axios from "axios";
import Vue from "vue";
import i18n from "@/plugins/i18n";
// import Router from "@/router";

const headers = {
  "content-type": "application/json",
  Accept: "application/json",
  "Accept-Language": "en",
};

const API_GATEWAY = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_GATEWAY,
  headers,
});

API_GATEWAY.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = i18n.locale;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API_GATEWAY.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // if (error.response.data.error === "Unauthorized") {
    if (error) {
      // if (error.response.data.message) {
      //   Vue.$toast.error(error.response.data.message, {
      //     icon: this.$vuetify.icons.values.errorIcon,
      //   });
      // }
      // window.location = `${process.env.VUE_APP_BASE1_URL}/auth/login`;
      // check the exception states if it's not defined there, it will be routed to the login page.
      // if (!exception()) {
      // await Router.replace({ name: "root" });
      // }
    }

    if (error && error.response.config.method !== "get") {
      Vue.$toast.clear();
      if (error.response.data.errors) {
        Object.values(error.response.data.errors).forEach((value) => {
          Vue.$toast.error(value[0]);
        });
      } else if (
        (!error.response.data.errors ||
          error.response.data.errors.length === 0) &&
        error.response.data.message
      ) {
        Vue.$toast.error(error.response.data.message);
      }
    }
    return Promise.reject(error.response);
  }
);

// const exception = () => Router.history.current.name === "forgetPassword";

export default API_GATEWAY;
