<script>
export default {
  props: ["clr"],
};
</script>
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 10.9333V7.81667"
      stroke="#E25254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99921 13.4375C9.88421 13.4375 9.79088 13.5308 9.79171 13.6458C9.79171 13.7608 9.88504 13.8542 10 13.8542C10.115 13.8542 10.2084 13.7608 10.2084 13.6458C10.2084 13.5308 10.115 13.4375 9.99921 13.4375"
      stroke="#E25254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6908 3.44916L18.0733 14.6192C18.815 15.9175 17.8775 17.5333 16.3825 17.5333H3.61751C2.12167 17.5333 1.18417 15.9175 1.92667 14.6192L8.30917 3.44916C9.05667 2.13999 10.9433 2.13999 11.6908 3.44916Z"
      stroke="#E25254"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
