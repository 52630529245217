<script>
export default {
  props: ["clr"],
};
</script>
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0002 10.9336V7.81689"
      stroke="#F09811"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99933 13.4373C9.88433 13.4373 9.791 13.5307 9.79183 13.6457C9.79183 13.7607 9.88517 13.854 10.0002 13.854C10.1152 13.854 10.2085 13.7607 10.2085 13.6457C10.2085 13.5307 10.1152 13.4373 9.99933 13.4373"
      stroke="#F09811"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.691 3.44916L18.0735 14.6192C18.8151 15.9175 17.8776 17.5333 16.3826 17.5333H3.61763C2.12179 17.5333 1.18429 15.9175 1.92679 14.6192L8.30929 3.44916C9.05679 2.13999 10.9435 2.13999 11.691 3.44916Z"
      stroke="#F09811"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
