import MainService from "@/services/modules/mainService";
// import router from "@/router";

export default {
  namespaced: true,
  state: {
    orderInfo: {},
  },

  getters: {
    GET_ORDER_INFO: (state) => state.orderInfo,
  },

  mutations: {
    MUTATE_ORDER_INFO: (state, payload) => (state.orderInfo = payload),
  },

  actions: {
    ACTION_ORDER_INFO: ({ commit }, params) => {
      return MainService.getInfo(params.trackingCode).then((response) => {
        commit("MUTATE_ORDER_INFO", response.data.data);
        return response;
      });
    },
    INIT_ORDER: ({ dispatch }, params) => {
      return dispatch("ACTION_ORDER_INFO", params);
    },
  },
};
