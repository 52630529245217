<script>
export default {
  props: ["clr"],
};
</script>
<template>
  <svg
    width="95"
    height="94"
    viewBox="0 0 95 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.4573 2.75419C45.9799 1.3903 49.0201 1.39029 51.5427 2.75419L67.9299 11.6144L83.7967 21.376C86.2391 22.8787 87.7592 25.5116 87.8393 28.3782L88.3598 47L87.8393 65.6218C87.7592 68.4884 86.2391 71.1213 83.7967 72.624L67.9299 82.3856L51.5427 91.2458C49.0201 92.6097 45.9799 92.6097 43.4573 91.2458L27.0701 82.3856L11.2033 72.624C8.7609 71.1213 7.24078 68.4884 7.16066 65.6218L6.64019 47L7.16066 28.3782C7.24078 25.5116 8.76089 22.8787 11.2033 21.376L27.0701 11.6144L43.4573 2.75419Z"
      stroke="#F2F2F0"
    />
    <path
      d="M47.9429 15.5C49.0057 15.5 50.0499 15.7791 50.971 16.3095L50.9708 16.3098L72.845 28.9389L72.8455 28.9392C73.7698 29.4715 74.5375 30.238 75.0713 31.1615C75.605 32.085 75.886 33.1329 75.8857 34.1996V34.1997V59.4643V59.4645C75.886 60.53 75.6058 61.5769 75.0731 62.4998C74.5405 63.4227 73.7742 64.189 72.8514 64.7218L50.9772 77.3509C50.0547 77.8836 49.0081 78.164 47.9429 78.164C46.8776 78.164 45.8311 77.8836 44.9085 77.3509L23.0343 64.7218C22.1115 64.189 21.3453 63.4227 20.8126 62.4998C20.28 61.5769 19.9997 60.53 20 59.4645V59.4643L20 34.1997L20 34.1995C19.9997 33.1339 20.28 32.0871 20.8126 31.1642C21.3453 30.2413 22.1115 29.4749 23.0343 28.9422L22.7843 28.5091L23.0343 28.9422L44.9085 16.313C45.8311 15.7804 46.8776 15.5 47.9429 15.5Z"
      stroke="#E25254"
    />
    <path
      d="M56.0371 56.1895L59.5042 59.6566"
      stroke="#E25254"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.9619 56.1899L35.4951 59.6568"
      stroke="#E25254"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse
      cx="47.5001"
      cy="47.7506"
      rx="12.005"
      ry="12.005"
      stroke="#E25254"
      stroke-width="3"
    />
    <path
      d="M36.4102 43.1437C34.9028 42.1906 33.9904 40.5302 33.9942 38.7468C33.9953 36.331 35.6436 34.2272 37.989 33.6479C40.3344 33.0687 42.7726 34.1633 43.8983 36.3008"
      stroke="#E25254"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.1016 36.3008C52.2273 34.1633 54.6655 33.0687 57.0109 33.6479C59.3563 34.2272 61.0045 36.331 61.0057 38.7468C61.0094 40.5302 60.0971 42.1906 58.5897 43.1437"
      stroke="#E25254"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.5003 47.4086V44.002"
      stroke="#E25254"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.5003 51.1696C47.4082 51.1696 47.3335 51.2442 47.3335 51.3363V51.3384C47.3352 51.4308 47.4113 51.5044 47.5037 51.5031C47.5961 51.5017 47.67 51.4259 47.6691 51.3335C47.6681 51.2411 47.5926 51.1669 47.5002 51.1675"
      stroke="#E25254"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
