import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import orderInfo from "./modules/orderInfo";

export default new Vuex.Store({
  modules: {
    orderInfo,
  },
});
