import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import CustomIcons from "../utiles/customIcons";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: CustomIcons,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        white: "#FFFFFF",
        gray1: "#F8F8F6",
        gray2: "#F2F2F0",
        gray3: "#E7E7E7",
        gray4: "#DBDCDD",
        gray5: "#CCCED0",
        gray6: "#B1B2B7",
        gray7: "#93959D",
        gray8: "#7D808A",
        gray9: "#696C75",
        gray10: "#50535B",
        gray11: "#3E4148",
        gray12: "#2A2C30",
        gray13: "#21242B",
        black: "#000000",
        snackbarBg: "#2A2C30",
        background: "#F5F5F5",
        onPrimary: "#F8F8F6",
        primary: "#8A6DDE",
        primary1: "#EFEDF8",
        primary2: "#E2DCF4",
        primary3: "#B09EE6",
        primary4: "#7D65DC",
        primary5: "#7262D0",
        primary6: "#7262D0",
        primary7: "#3726A7",
        success: "#3AAB0D",
        successBg: "#EBF7E7",
        info: "#3889D0",
        infoBg: "#E7F1F9",
        warning: "#F18F01",
        warningBg: "#FEF4E6",
        error: "#E25254",
        errorBg: "#FCEEEE",
      },
    },
  },
});
